@use "../config" as *;
.waf-listing {
    &.home-news-list {
        background: none;
        @extend %mx-3-neg;
        &:before {
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            pointer-events: none;
            @include background(null, "patterns/mobile/mobile-latest-update-bg.png", top/cover repeat-x);
        }
        .head-wrap {
            .title {
                @extend %secondary-1100;
            }
        }
        .article-item {
            @extend %article-item-scale;
        }
        .views-section {
            @extend %d-none;
        }
    }
}
.home-news-list,
.news-list {
    .layout-wrapper {
        @extend %px-3;
        @extend %py-6;
        &:before {
            @extend %h-100;
        }
    }
    .article-content{
        @extend %pt-9;
        .meta-category{
            bottom: calc(100% - 3.5rem);
            @extend %absolute;
        }
    }
    .article-title {
        @extend %secondary-1000;
    }
    .first-list,
    .preview-swiper .article-list {
        --_line: 2;
        --_fontsize: 15;
        // --_line-height: 2.2;
        @extend %overflow;
        @extend %mb-3;
        @include card-count(1.1, var(--space-4));
        .article {
            &-item {
                overflow: unset;
                @extend %full-radius;
                @extend %relative;
            }
            &-content {
                width: calc(100% - 3rem);
                margin-inline: auto;
                margin-top: -20%;
                @extend %px-3;
                @extend %pb-3;
                @extend %pt-9;
                @extend %neutral-50-bg;
                @extend %full-radius;

                .icon-b-share::before {
                    @extend %secondary-800;
                }
            }
            &-title {
                @extend %mb-2;
            }
            &-description {
                @include truncate(2, 12);
                @extend %mb-2;
                @extend %neutral-800;
            }
        }
    }
    .second-list {
        --_line: 1;
        --_fontsize: 14;
        // --_line-height: 2.2;
        @include listing-card(horizontal);
        @include grid(1, var(--space-3));
        .article {
            &-title {
                @extend %mb-1;
            }
            &-item {
                @extend %neutral-50-bg;
            }
            &-description {
                @extend %d-none;
            }
        }
    }
    .article-content {
        padding-top: var(--space-9);
        .reaction-section {
            @extend %mt-0;
        }
    }
    .article-meta {
        @extend %mt-0;
    }
    .icon-b-share::before {
        @extend %secondary-1000-8;
        @extend %font-12;
    }
}
.news-list .layout-wrapper {
    padding-block: var(--space-6) 0;
}
@media screen and (min-width: $tablet-min-width) {
    .waf-listing {
        &.home-news-list {
            &::before {
                z-index: -1;
                isolation: isolate;
                @include background(null, "patterns/latest-update-bg.png", top/cover repeat-x);
            }
            .layout-wrapper {
                padding-inline: 0;
                padding-block: var(--space-12);
            }
            .first-list {
                --_line: 3;
                --_fontsize: 18;
                // --_line-height: 2.7;
                @include listing-card(horizontal);
                .article {
                    &-content {
                        margin-top: unset;
                        width: 100%;
                        border-radius: 0 var(--full-radius) var(--full-radius) 0;
                    }
                    &-title {
                        margin-bottom: 0;
                    }
                    &-thumbnail {
                        width: 24rem;
                        flex-shrink: 0;
                        .img-box {
                            border-radius: var(--full-radius) 0 0 var(--full-radius);
                        }
                    }
                    &-description {
                        @include truncate(4, 14);
                        color: clr(neutral-600);
                    }
                }
            }
            .second-list {
                --_fontsize: 16;
                // --_line-height: 2.2;
                @include grid(2, var(--space-3));
            }
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-listing {
        &.home-news-list {
            .first-list {
                overflow: unset;
                @include card-count(2, var(--space-4));
            }
            .second-list {
                @include grid(3, var(--space-3));
            }
        }
    }
}