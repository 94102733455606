@use "../config/" as *;
.waf-row-tracker {
  padding: var(--space-4) var(--space-1);
  overflow-x: clip;
  @include background(var(--secondary-1000), "patterns/mobile/mobile-tracker-bg.png", top center / contain repeat-x);
  & > * {
    @extend %neutral-50;
  }
}

.waf-tracker {
  .head {
    &-wrap {
      @extend %mb-3;
      .title {
        @extend %neutral-50;
        @extend %font-18-pm;
      }
    }
    
    &-tab li  a{
      color: var(--neutral-50);
    }
  }
  .tracker {
    @include flex-config(flex, column);
    &-info-wrapper {
      @extend %d-none;
    }
    &-content-list {
      @extend %flex-column;
    }
    &-content-item {
      @extend %relative;
      @extend %py-6;
      @extend %flex-c-c;
      &::before {
        content: '';
        background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
        height: 0.1rem;
        opacity: 0.3;
        @extend %pos-tl;
        @extend %w-100;
      }
    }
    &-title {
      @extend %uppercase;
      @extend %font-14-pr;
    }
    &-content {
      .count {
        height: 3.2rem;
        @extend %font-28-pm;
        @extend %neutral-50;
      }
      .content {
        @include flex-config(flex, column);
        @extend %gap-3;
        @extend %text-center;
      }
      .label {
        @extend %uppercase;
      }
    }
    &-content-wrap {
      @extend %flex-column;
      @extend %w-100;
    }
    &-middle-section {
      order: 3;
    }
    &-right-section {
      .tracker {
        &-content-list {
          @include flex-config(flex, row);
          @extend %flex-wrap;
          @extend %relative;
          &::before {
            content: '';
            background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
            height: 0.1rem;
            opacity: 0.3;
            @extend %pos-y-center;
            @extend %w-100;
          }
        }
        &-content-item {
          @extend %w-100;
          &.orange-cap,
          &.purple-cap {
            @extend %w-50;
            .count {
              height: 2.2rem;
            }
            &::before {
              @extend %d-none;
            }
          }
        }
      }
    }
    &-bottom-section {
      order: 4;
    }
  }
  .graph-svg {
    margin: var(--space-2) auto;
    isolation: isolate;
    position: relative;
    &::before {
      content: '';
      width: 80%;
      height: 80%;
      z-index: var(--z-patterns);
      @extend %mt-1;
      @extend %circle-radius;
      @extend %pos-center;
      @extend %secondary-500-bg;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .bowling-types {
    .tracker {
      &-title {
        @extend %text-center;
        @extend %font-14-pr;
      }
    }
    .graph {
      &-svg {
        width: 20rem;
        height: 20rem;
      }
      &-ledger {
        @extend %flex-c-c;
        @extend %gap-6;
      }
    }
    .ledger {
      &-text {
        @include flex-config(flex, column-reverse);
        @extend %gap-4;
        .ledger-count {
          height: 3.2rem;
        }
        &1 {
          align-items: flex-end;
          .ledger-count {
            @extend %primary-500;
          }
        }
        &2 {
          .ledger-count {
            @extend %secondary-500;
          }
        }
      }
      &-label {
        @extend %font-18-pr;
        @extend %uppercase;
      }
      &-count {
        @extend %font-28-pm;
      }
    }
  }
  .stumping {
    .content {
      @extend %w-100;
      @extend %relative;
      &:first-child {
        @extend %pb-6;
        @extend %relative;
        &::after {
          content: '';
          background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
          opacity: 0.3;
          height: 0.1rem;
          @extend %pos-bl;
          @extend %w-100;
        }
      }
      &:not(:first-child) {
        @extend %w-50;
        @extend %py-6;
      }
      &:nth-child(2) {
        &::after {
          content: '';
          background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
          width: 0.1rem;
          opacity: 0.3;
          @extend %pos-tl;
          @extend %h-100;
        }
      }
    }
    .swiper-slide {
      flex-direction: row-reverse;
      @extend %flex-wrap;
      @extend %flex;
    }
  }
  .orange-cap,
  .purple-cap {
    .tracker {
      &-title {
        @extend %mb-3;
        @extend %pl-4;
        @extend %relative;
        @extend %flex-c-c;
        &::before {
          content: '';
          left: 1rem;
          @include icon(cap, 25);
          @extend %absolute;
        }
      }
      &-sub-title {
        @extend %font-14-pr;
        @extend %uppercase;
        @extend %mb-1;
      }
      &-content {
        @extend %gap-1;
        @include flex-config(flex, row-reverse, center, center);
        .label,
        .count {
          @extend %font-18-pm;
          @extend %neutral-50;
        }
      }
      &-content-wrap {
        @extend %text-center;
      }
    }
  }
  .orange-cap {
    order: 2;
    @extend %relative;
    &::after {
      content: '';
      background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
      width: 0.1rem;
      opacity: 0.3;
      @extend %pos-tr;
      @extend %h-100;
    }
    .tracker-title::before {
      @extend %warning-500;
    }
  }
  .purple-cap {
    order: 3;
    .tracker-title::before {
      @extend %secondary-500;
    }
  }
  .total-runs,
  .total-wickets {
    padding-block: var(--space-8);
    .tracker {
      &-content-wrap {
        width: max-content;
        padding-left: calc(var(--space-8) * 2);
        @extend %gap-3;
        @extend %relative;
        @extend %flex-column-c-n;
        &::before {
          content: '';
          flex-shrink: 0;
          width: 6rem;
          height: 6.6rem;
          left: 0;
          @include background(null, "svg/total-run.svg", top / cover no-repeat);
          @extend %absolute;
        }
      }
    }
  }
  .total-wickets {
    order: 4;
    .tracker-content-wrap {
      padding-left: var(--space-12);
      gap: var(--space-2);
      &::before {
        width: 4.7rem;
        height: 6.4rem;
        @include background(null, "svg/total-wickets.svg", top / cover no-repeat);
      }
    }
  }
  .runs-powerplay,
  .free-hits {
    @extend %text-center;
    .tracker-content-wrap {
      @extend %gap-5;
    }
  }
  .runs-scored-4s {
    padding-block: var(--space-4);
    .total-number {
      @extend %d-none;
    }
    .tracker-content-wrap {
      align-items: center;
      gap: var(--space-2);
    }
    .progress {
      &-wrapper {
        @extend %flex-c-c;
        @extend %gap-5;
      }
      &-option {
        @include flex-config(flex, column-reverse);
        @extend %text-center;
        &-a {
          .number {
            @extend %primary-500;
          }
        }
        &-b {
          .number {
            @extend %secondary-500;
          }
        }
        .label {
          @extend %font-14-pr;
        }
        .number {
          @extend %font-28-pm;
        }
      }
    }
    .graph-box {
      width: 12rem;
      height: 12rem;
      @extend %neutral-50;
      @extend %my-1;
      @extend %relative;
      &::before {
        content: '';
        @include background(null, "svg/ground-4s.svg", center / 70% no-repeat);
        @extend %pos-center;
        @extend %w-100;
        @extend %h-100;
      }
      svg {
        width: 100%;
        height: 100%;
      }
      .circle-inner {
        stroke: var(--secondary-500);
        stroke-width: 2;
        stroke-linecap: round;
      }
      .circle-outer {
        stroke: var(--primary-500);
        stroke-width: 2;
        transform-origin: center;
        stroke-linecap: round;
        animation: circle-chart-fill 2s reverse;
        rotate: 90deg;
        scale: -1 1;
      }
    }
  }
  .runs-free-hit {
    gap: var(--space-3);
  }
  .over-wrap {
    @extend %relative;
    &::after {
      content: '';
      background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
      width: 0.1rem;
      opacity: 0.3;
      @extend %pos-x-center;
      @extend %h-100;
    }
    .tracker {
      &-content-item {
        padding-block: var(--space-0);
        @extend %text-center;
        @extend %w-50;
        &::before {
          @extend %d-none;
        }
      }
      &-content-wrap {
        @extend %gap-3;
      }
    }
  }
}
@include mq(col-md) {
  .waf-tracker {
    .head {
      &-wrap .title {
        font-size: 2.2rem;
        width: max-content;
        flex-shrink: 0;
      }
      &-tab li {
        gap: var(--space-3);
        font-size: 1.2rem;
        flex-direction: row;
        @include flex-config(flex, null, null, center);
        a {
          font-size: 1.2rem;
          margin-top: var(--space-0);
        }
      }
    }
    .tracker {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, auto);
      &-content-item {
        padding-block: var(--space-4);
        &::before {
          display: none;
        }
      }
      &-title {
        font-size: 1.6rem;
      }
      &-content {
        .count {
          height: 3.6rem;
          font-size: 3rem;
        }
      }
      &-content-list {
        position: relative;
        &::before,
        &::after {
          content: '';
          background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
          height: 0.1rem;
          opacity: .3;
          width: 100%;
          @include position-combo(tl);
        }
        &::after {
          top: 100%;
        }
      }
      &-left-section {
        display: grid;
        grid-area: 1 / 1 / 2 / 4;
        .tracker {
          &-content-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 7rem);
          }
        }
      }
      &-middle-section {
        display: grid;
        grid-area: 2 / 1 / 3 / 3;
        .tracker {
          &-content-list {
            flex-direction: row-reverse;
            position: relative;
            &::before {
              content: '';
              background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
              height: 100%;
              opacity: .3;
              width: 0.1rem;
              display: block;
              @include position-combo(x-center);
            }
          }
          &-content-item {
            width: 100%;
          }
        }
        .over-wrap {
          flex-direction: column;
        }
      }
      &-right-section {
        display: grid;
        grid-area: 2 / 3 / 3 / 4;
        .tracker-content-item {
          &.purple-cap,
          &.orange-cap {
            width: 100%;
          }
          &.orange-cap {
            position: relative;
            &::before,
            &::after {
              content: '';
              background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
              height: 0.1rem;
              opacity: .3;
              width: 100%;
              display: block;
              @include position-combo(tl);
            }
            &::after {
              top: 100%;
            }
          }
        }
        .tracker-content-list {
          padding-left: var(--space-2);
          &::before {
            content: '';
            background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
            height: 100%;
            opacity: .3;
            width: 0.1rem;
            display: block;
            left: 0;
          }
        }
      }
      &-bottom-section {
        display: grid;
        grid-area: 3 / 1 / 4 / 4;
        .stumping {
          .swiper-slide {
            flex-wrap: nowrap;
            align-items: center;
            .content {
              width: 100%;
              padding-block: var(--space-0);
            }
          }
        }
        .tracker-content-list::after {
          display: none;
        }
      }
    }
    .runs-powerplay {
      grid-area: 1 / 2 / 2 / 3;
      padding: var(--space-0) var(--space-4);
      &::before {
        display: block;
        top: 100%;
      }
      .tracker-content-wrap {
        @include flex-config(flex, row, space-between, center);
      }
    }
    .runs-scored-4s {
      grid-area: 2 / 2 / 4 / 3;
      padding: var(--space-0) var(--space-4);
      .graph-box {
        width: 8rem;
        height: 8rem;
      }
      .tracker-title {
        width: 100%;
      }
      .progress-wrapper {
        gap: var(--space-10);
      }
    }
    .over-wrap {
      justify-content: space-around;
      &::after {
        width: 100%;
        height: 0.1rem;
        background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
      }
      .tracker-content-item {
        padding-block: var(--space-10);
      }
      .tracker-content-wrap {
        gap: var(--space-2);
      }
    }
    .free-hits {
      padding-block: var(--space-4);
      .tracker-content-wrap {
        @include flex-config(flex, row, space-between, center);
      }
    }
    .orange-cap,
    .purple-cap {
      .tracker {
        &-content-wrap {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
        }
        &-title {
          width: 100%;
          justify-content: flex-start;
          padding-left: var(--space-12);
          margin-bottom: var(--space-4);
          &::before {
            font-size: 3.4rem;
            left: 0;
          }
        }
        &-sub-title,
        &-content {
          width: 50%;
        }
        &-sub-title {
          margin-bottom: var(--space-0);
          font-size: 1.8rem;
        }
        &-content {
          .label,
          .count {
            font-size: 2rem;
          }
        }
      }
    }
    .stumping {
      padding-block: var(--space-12);
      &::after {
        content: "";
        background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
        height: 0.1rem;
        opacity: .3;
        width: 100%;
        @include position-combo(bl);
      }
      .label {
        font-size: 1.6rem;
      }
      .content {
        gap: var(--space-2);
        &:first-child::after {
          width: 0.1rem;
          height: 100%;
          left: 0;
          background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
        }
      }
    }
    .total-runs,
    .total-wickets {
      padding-top: var(--space-8);
      position: relative;
      &::after {
        content: '';
        background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
        height: 100%;
        opacity: .3;
        width: 0.1rem;
        display: block;
        @include position-combo(tr);
      }
      .tracker-content-wrap {
        padding-left: 0;
        &::before {
          top: -100%;
        }
      }
    }
    .total-runs {
      grid-area: 1 / 1 / 4 / 2;
      padding-inline: var(--space-3);
    }
    .total-wickets {
      grid-area: 1 / 3 / 4 / 4;
      padding-inline: var(--space-3);
      &::after {
        @include position-combo(tl);
      }
    }
  }
}
@include mq(col-lg) {
  .waf-row-tracker {
    position: relative;
    isolation: isolate;
    background-image: none;
    padding-top: var(--space-12);
    &::before {
      content: '';
      width: 100%;
      height: calc(100% + 25rem);
      padding: var(--space-4) var(--space-1);
      pointer-events: none;
      z-index: var(--z-patterns);
      top: 0;
      @include position-combo(x-center);
      @include background(var(--secondary-1000), "svg/patterns/pattern1.svg", top center / 100% auto no-repeat);
    }
  }
  .waf-tracker {
    .tracker-right-section .tracker-content-list {
      padding-left: var(--space-6);
    }
    .total-runs {
      padding-left: 4rem;
    }
    .total-wickets {
      padding-left: 5rem;
    }
    .total-wickets,
    .total-runs {
      .tracker-content-wrap::before {
        top: auto;
      }
    }
    .total-wickets .tracker-content-wrap::before {
      left: -6rem;
    }
    .total-runs .tracker-content-wrap::before {
      left: -8rem;
    }
    .free-hits {
      padding-right: var(--space-6);
    }
  }
}