@use "../config" as *;
.waf-listing {
    &.home-video-listing {
        @extend %relative;
        // for-meta-category
        .first-list .article-content {
            padding-top: 0;
        }
        .article-item {
            .article-content {
                @extend %relative;
                .meta-category {
                    bottom: calc(100% + 1.5rem);
                    @extend %absolute;
                }
            }
        }
        &::before {
            content: "";
            width: var(--window-inner-width);
            z-index: var(--z-patterns);
            left: calc(var(--container-white-space) * -1);
            pointer-events: none;
            @extend %h-100;
            @extend %absolute;
            @extend %secondary-1000-bg;
        }
        &::after {
            content: "";
            transform: rotate(90deg);
            width: 10rem;
            height: var(--window-inner-width);
            top: -14rem;
            pointer-events: none;
            @include background(null, "svg/patterns/pattern-2.svg", left no-repeat);
            @extend %pos-x-center;
            @extend %absolute;
            @extend %h-100;
        }
        .swiper-button-prev,
        .swiper-button-next {
            @extend %d-none;
        }
        .title {
            @extend %neutral-50;
        }
        .layout-wrapper {
            @extend %py-6;
        }
        .article {
            &-content {
                @extend %flex;
            }
            &-title {
                --_fontsize: 16;
                @extend %mb-3;
                @extend %neutral-50;
            }
            &-meta {
                @extend %neutral-50;
                @extend %mb-0;
            }
            &-thumbnail {
                @extend %relative;
                &::after {
                    content: "";
                    background-image: linear-gradient(to top, var(--neutral-900), transparent);
                    pointer-events: none;
                    z-index: var(--z-overlay);
                    position: absolute;
                    inset: 0;
                }
                .item-type-icon {
                    z-index: var(--z-video-icon);
                }
            }
        }
        .second-list {
            .article-title {
                @extend %mb-1;
            }
        }
        .meta-date {
            @extend %neutral-50;
        }
        .reaction-section {
            @extend %mb-1;
        }
        .first-list {
            @include listing-card(overlay);
        }
        .second-list {
            flex-wrap: nowrap;
            @include listing-card(card);
            @include card-count(2.02, var(--space-3));
            @extend %x-auto;
            @extend %pt-3;
            .img-box {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
            }
            .article {
                &-title {
                    --_fontsize: 12;
                    @extend %secondary-1000;
                }
                &-content {
                    @extend %neutral-50-bg;
                    .meta-category {
                        @extend %neutral-1000-bg-7;
                        @extend %primary-500;
                    }
                }
            }
            .tagline,
            .category {
                @include position(var(--space-6-neg), null, null, var(--space-2));
            }
            .item-type-video .item-type-icon {
                box-shadow:
                    0 0 0.3rem hsl(var(--hsl-neutral-50) / 0.2),
                    0 0 0 1rem hsl(var(--hsl-neutral-50) / 0.05);
                &::before {
                    @extend %font-10;
                }
            }
            .icon-b-share::before,
            .meta {
                @extend %secondary-1000;
            }
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        &.home-video-listing {
            --_swiper-button-height: 2rem;
            background: var(--secondary-1000);
            margin-inline: var(--space-3-neg);
            @include listing-card(card);
            &::before {
                content: "";
                left: 0;
                z-index: var(--z-index0);
                @include background(null, "svg/patterns/pattern-2.svg", left/contain no-repeat);
            }
            &::after {
                display: none;
            }
            .layout-wrapper {
                max-width: unset;
                position: relative;
                padding-block: 0;
            }
            .waf-head {
                position: absolute;
                top: var(--space-10);
                width: 100%;
                padding-inline: var(--container-white-space);
            }
            .head-tab {
                z-index: var(--z-index2);
                margin-right: calc(2 * var(--swiper-button-width) + var(--space-2));
            }
            .title {
                color: clr(neutral-50);
            }
            .first-list {
                @include listing-card(vertical);
                .article {
                    &-content {
                        background-color: transparent;
                        .icon-b-share::before {
                            color: clr(neutral-50);
                        }
                        .tagline,
                        .category,
                        .meta-category {
                            background-color: clr(neutral-1000, 7);
                            a {
                                color: clr(primary-500);
                            }
                        }
                    }
                    &-meta {
                        .meta {
                            color: clr(neutral-50);
                            &.meta-category {
                                color: clr(primary-500);
                            }
                        }
                    }
                    &-item:hover {
                        .article {
                            &-content {
                                background: inherit;
                                .tagline,
                                .category,
                                .meta-category {
                                    background: clr(neutral-1000, 7);
                                    color: clr(primary-500);
                                }
                            }
                        }
                    }
                }
            }
            .article {
                &-title {
                    --_fontsize: 16;
                }
                &-list {
                    gap: 0;
                    margin-inline: var(--space-3-neg);
                }
                &-item {
                    width: 35rem;
                    @include transition(100ms ease-in-out);
                    &:not(.swiper-slide-next) {
                        scale: 0.9;
                    }
                }
                &-content {
                    padding-inline: 0;
                }
            }
            .item-type-video .item-type-icon {
                box-shadow: 0 0 0 1.1rem hsl(var(--hsl-neutral-50)/0.2), 0 0 0 1.8rem hsl(var(--hsl-neutral-50)/0.05);
                @include square(4rem);
                &::before {
                    font-size: 2rem;
                }
            }
            .swiper {
                padding: var(--space-18) var(--container-white-space) var(--space-6);
                &-slide {
                    opacity: 0;
                    .article-content {
                        padding-top: var(--space-9);
                        .meta-category {
                            bottom: calc(100% - 3.5rem);
                        }
                    }
                }
                &-slide-next {
                    border-radius: 0;
                    overflow: visible;
                    .item-type-icon {
                        @include square(6rem);
                        &::before {
                            font-size: 2.5rem;
                        }
                    }
                    .img-box {
                        pointer-events: none;
                        overflow: unset;
                    }
                    .article {
                        &-title {
                            --_fontsize: 29;
                        }
                        &-content {
                            width: calc(var(--window-inner-width)* .34);
                            left: -10%;
                            pointer-events: none;
                        }
                        &-thumbnail {
                            aspect-ratio: 4 / 3;
                            &::after {
                                display: none;
                            }
                            .img-box {
                                background: unset;
                                img {
                                    position: absolute;
                                    top: var(--space-18-neg);
                                    left: -35%;
                                    width: calc(var(--window-inner-width) * 0.70);
                                    aspect-ratio: 4 / 3;
                                    height: auto;
                                    @include mask(linear-gradient(to right, transparent 2%, black, transparent));
                                    pointer-events: none;
                                }
                            }
                        }
                    }
                }
                &-slide-active,
                &-slide-next,
                &-slide-next + .swiper-slide {
                    opacity: 1;
                }
                &-slide-active {
                    z-index: var(--z-index1);
                }
                &-button-next,
                &-button-prev {
                    display: flex;
                    top: calc(var(--space-10) + 0.4rem);
                    height: var(--_swiper-button-height);
                    &::after {
                        font-size: 0.6rem;
                    }
                }
                &-button-next {
                    right: var(--container-white-space);
                    border-radius: 0 var(--half-radius) var(--half-radius) 0;
                }
                &-button-prev {
                    left: auto;
                    right: calc(var(--container-white-space) + var(--swiper-button-width));
                    border-radius: var(--half-radius) 0 0 var(--half-radius);
                    &::after {
                        font-size: 0.6rem;
                    }
                }
            }
        }
    }
}