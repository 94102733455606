@use "../config/" as *;
.waf-row-fixture {
  padding-block: var(--space-6);
  @include background(var(--neutral-50), "patterns/home-fixture-bg.png", top/auto repeat-x);
  .accordion-head {
    @extend %d-none;
  }
  .head-wrap {
    align-items: flex-start;
  }
  .head-tab {
    flex-wrap: wrap;
    @extend %flex-fe-c;
    @extend %gap-1;
    li {
      &:first-child {
        order: 3;
        a {
          @extend %primary-500-bg;
        }
      }
      &:nth-child(2) {
        order: 2;
        a,
        .download {
          border: 0.05rem solid var(--secondary-1000);
          width: 2rem;
          height: 2rem;
          @extend %font-0;
          &::after {
            @include icon(download, 12);
            @extend %pos-center;
          }
        }
      }
      &:last-child {
        order: 1;
        a,
        .calendar {
          height: 2rem;
          border: 0.05rem solid var(--secondary-1000);
          background-color: unset;
          @extend %flex-c-c;
          @extend %font-10-pm;
          &::after {
            @include icon(calendar, 10);
            @extend %ml-1;
          }
        }
      }
    }
  }
  .waf-body {
    margin-inline: calc(var(--container-white-space) * -1);
    @extend %flex-c-c;
    @extend %pt-3;
  }
  .score-strip-slider {
    overflow-x: auto;
  }
  .card {
    &-list {
      @extend %w-100;
      @extend %h-100;
      @extend %flex-n-c;
      @extend %gap-3;
      @extend %px-3;
    }
    &-item {
      flex-shrink: 0;
      width: 92%;
      border: 0.1rem solid var(--secondary-100);
      border-radius: 0.5rem;
      overflow: hidden;
      @extend %p-3;
      @extend %secondary-50-bg;
      @extend %relative;
      & > * {
        @extend %secondary-1000;
      }
    }
    &-head {
      @extend %pb-6;
      @extend %gap-2;
      @extend %flex-n-c;
      @extend %relative;
      & > * {
        letter-spacing: 0.02rem;
        @extend %font-10-pr;
      }
      .status {
        border: 0.08rem solid var(--secondary-1000);
        border-radius: 0.3rem;
        line-height: 1;
        padding-block: .3rem;
        @extend %pos-tr;
        @extend %px-2;
        @extend %font-10-pm;
        @extend %uppercase;
      }
    }
    &-venue {
      top: 1.5rem;
      @extend %absolute;
      &::before {
        content: '';
        @include icon(venue, 12);
      }
    }
    &-label {
      border: 0.1rem solid var(--neutral-100);
      @extend %absolute;
      @extend %py-2;
      @extend %secondary-1000;
      @extend %d-none;
    }
    &-footer {
      height: 5rem;
      @extend %secondary-200-bg;
      @extend %mx-3-neg;
      @extend %mb-3-neg;
      @extend %mt-2;
      @extend %flex-c-c;
      .btn-scorecard {
        top: 0;
        cursor: pointer;
        @extend %absolute;
        @extend %font-0;
        @extend %h-100;
        @extend %w-100;
        @extend %d-block;
      }
      .btn-ticket {
        z-index: 1;
        position: relative;
      }
    }
    &-footer-text {
      @extend %text-center;
      @extend %px-3;
      @extend %py-2;
      @extend %font-12-pr;
      @extend %secondary-1000;
    }
    &-number {
      @extend %font-10-pm;
      @extend %relative;
      @extend %mr-1;
      &::before {
        content: '';
        height: 1.5rem;
        width: .1rem;
        @include position(-.1rem, -.6rem);
        @extend %secondary-1000-bg;
      }
    }
  }
  .recent {
    .team:not(.team-won) {
      .team-name,
      .team-data-wrap {
        opacity: 0.6;
      }
    }
    .status {
      border-color: var(--error-500);
      @extend %error-500;
    }
  }
  .live {
    .status {
      border-color: var(--success-500);
      padding-left: var(--space-5);
      @extend %success-500;
      @extend %flex-n-c;
      @extend %relative;
      &::before {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        left: 0.8rem;
        pointer-events: none;
        animation: blinker 2s linear 0s infinite normal forwards;
        @include background(null, "svg/live.svg", center / contain no-repeat);
        @extend %absolute;
      }
    }
    .team {
      &:not(.team-current) {
        .team-name,
        .score-super-over {
          opacity: 0.6;
        }
      }
      &-current .team-image {
        border-color: var(--primary-500);
      }
    }
  }
  .upcoming {
    .status {
      padding-inline: var(--space-2);
    }
    .team {
      &-detail {
        @extend %flex-sb-c;
      }
    }
    .countdown {
      &-wrapper,
      &-list {
        @extend %flex-c-c;
      }
      &-wrapper {
        .icon {
          width: 1.5rem;
          height: 1.5rem;
          @extend %relative;
          &::before {
            content: '';
            left: -1rem;
            @include icon(stopwatch, 15);
            @extend %absolute;
          }
        }
      }
      &-item {
        border-right: 0.1rem solid var(--secondary-200);
        @extend %flex-column;
        @extend %text-center;
        @extend %px-2;
        @extend %py-2;
        @extend %gap-1;
        &:first-child {
          border-left: 0.1rem solid var(--secondary-200);
        }
      }
      &-count {
        @extend %font-14-pm;
      }
      &-label {
        opacity: 0.6;
        @extend %font-8-pr;
        @extend %uppercase;
      }
    }
    .card {
      &-footer {
        @extend %flex-c-c;
      }
      &-footer-text {
        @extend %text-left;
      }
    }
    .btn-group {
      flex-shrink: 0;
    }
    .btn-ticket {
      @extend %font-12-pm;
      @extend %relative;
      @extend %pr-5;
      &::before {
        content: '';
        right: 1rem;
        @include icon(double-arrow, 8);
        @extend %pos-y-center;
      }
    }
  }
  .date {
    @extend %relative;
    @extend %mr-2;
    &::before {
      content: '';
      height: 1.5rem;
      width: .1rem;
      @include position(-.1rem, -0.7rem);
      @extend %secondary-1000-bg;
    }
  }
  .team {
    @extend %flex-sb-c;
    &-a {
      @extend %mb-3;
    }
    &-data,
    &-info {
      @extend %flex-n-c;
    }
    &-info {
      @extend %gap-2;
    }
    &-image {
      width: 3.2rem;
      height: 3.2rem;
      flex-shrink: 0;
      border: 0.168rem solid var(--secondary-200);
      padding: 0.3rem;
      // box-shadow: 0 0 0.5rem var(--secondary-300);
      @extend %circle-radius;
      @extend %neutral-50-bg;
      @extend %flex-c-c;
    }
    &-logo {
      object-fit: contain;
      @extend %h-100;
    }
    &-name {
      @extend %font-16-pm;
    }
    &-score {
      @extend %flex;
      @extend %mr-1;
    }
    &-extra {
      @extend %flex-c-c;
    }
    &-won {
      .team-image {
        border-color: var(--primary-500);
      }
    }
  }
  .super-over {
    .team {
      &-data-wrap {
        @extend %flex-c-c;
        @extend %gap-4;
      }
      &-data {
        @extend %relative;
        &:not(:first-child)::after {
          content: '/';
          left: -1.5rem;
          @extend %absolute;
          @extend %font-16-pm;
        }
        &:not(.score-super-over) {
          opacity: 0.6;
        }
      }
    }
  }
  .score {
    @extend %font-14-pm;
  }
  .overs {
    opacity: 0.6;
    min-width: 3rem;
    text-align: right;
    @extend %font-9-pr;
  }
  .full-name,
  .match-time,
  .run-rate,
  .swiper-button,
  .swiper-pagination {
    @extend %d-none;
  }
  .btn-scorecard {
    @extend %pos-tl;
    @extend %w-100;
    @extend %h-100;
    @extend %d-none;
  }
}
.waf-row-fixture {
  .waf-shimmer {
    .card {
      &-head {
        .status {
          border: 0;
          @include shimmer(20%, 2.2rem, 0);
          position: absolute;
          &::before {
            @extend %d-none;
          }
        }
      }
      &-number {
        @include shimmer(15%, 1.2rem, 0);
      }
      &-venue {
        @include shimmer(70%, 1.2rem, 0);
        position: absolute;
        &::before {
          font-size: 0;
        }
      }
      &-footer .btn-group {
        @extend %d-none;
      }
    }
    .short-name {
      opacity: 1;
      @include shimmer(3rem, 2rem, 0);
    }
    .date-time {
      @include shimmer(45%, 1.2rem, 0);
    }
    .team-image {
      border: 0;
      @include shimmer(3.3rem, 3.3rem);
    }
    .team-logo {
      opacity: 0;
    }
    .card-footer-text {
      @include shimmer(90%, 1.2rem, 0);
    }
    .team-data-wrap {
      @include shimmer(60%, 1.2rem, 0);
      .score,
      .overs,
      .run-rate {
        @extend %font-0;
      }
    }
    .live {
      .team {
        &:not(.team-current) {
          .team-name {
            opacity: unset;
          }
        }
      }
    }
    .recent {
      .team:not(.team-won) {
        .team-name,
        .team-data-wrap {
          opacity: 1;
        }
      }
    }
    .upcoming {
      .btn-ticket {
        @include shimmer(100%, 1.2rem, 0);
      }
      .countdown {
        &-count {
          @include shimmer(100%, 1.5rem, 0);
        }
        &-label {
          opacity: 1;
          @include shimmer(2rem, 1.2rem, 0);
        }
        &-wrapper .icon::before {
          opacity: 0;
        }
      }
    }
  }
}
@include mq(col-md) {
  .waf-row-fixture {
    padding-block: var(--space-12);
    .head-wrap .title {
      font-size: 2.2rem;
    }
    .head-tab {
      gap: var(--space-2);
    }
    .waf-body {
      margin-inline: 0;
      padding-top: var(--space-4);
    }
    .score-strip-slider {
      overflow-x: unset;
    }
    .card {
      &-head {
        & > * {
          font-size: 1.2rem;
        }
      }
      &-list {
        width: 100%;
        padding-inline: 0;
        @include card-count(1.8, 1.5rem);
      }
      &-venue {
        top: 1.7rem;
      }
    }
    .team {
      &-name {
        font-size: 1.8rem;
      }
    }
    .score {
      font-size: 1.6rem;
    }
    .overs {
      font-size: 1.1rem;
      min-width: 3.5rem;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1365px) {
  .waf-row-fixture {
    padding-inline: var(--space-6);
    .layout-wrapper {
      max-width: 100%;
    }
  }  
}
@include mq(col-lg) {
  .waf-row-fixture {
    .card {
      &-list {
        @include card-count(2.2, 1.5rem);
      }
    }
    .card-list {
      width: auto;
    }
    .score-strip-slider {
      width: 100%;
    }
  }
}
@include mq(col-xl) {
  .waf-row-fixture {
    .card {
      &-list {
        @include card-count(3, 1.5rem);
      }
    }
  }
}