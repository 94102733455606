@use "../config/" as *;
.waf-showcase.widget-layout-01 {
    position: fixed;
    left: 0;
    bottom: var(--standings-strip-height, 0rem);
    z-index: var(--z-marquee);
    margin: 0;
    height: var(--standings-strip-height, 0rem);
    @include separator(60%, y);
    @extend %w-100;
    @extend %neutral-1000-bg;
    @extend %flex;
    .layout-wrapper {
        max-width: unset;
        overflow: hidden;
    }
    &::after {
        left: var(--standings-strip-width, 0rem);
    }
    &::before {
        flex-shrink: 0;
        width: var(--standings-strip-width, 0rem);
        height: var(--standings-strip-height, 0rem);
        aspect-ratio: 1/1;
        @include icon(trending, 12);
        @extend %primary-500;
        @extend %flex-c-c;
    }

    .article {
        &-title {
            width: max-content;
            line-height: var(--standings-strip-height, 0rem);
            @extend %neutral-50;
            @extend %font-12-pr;
            @extend %px-3;
        }
        &-meta,
        &-readmore {
            @extend %d-none;
        }
        &-list {
            width: max-content;
            animation: marquee 50s linear infinite;
            @extend %flex;
            @extend %gap-3;
            &:hover {
                animation-play-state: paused;
            }
        }
        &-item {
            @extend %relative;
            &:not(:first-child):after {
                content: "";
                width: 0.1rem;
                height: 1.5rem;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                @extend %absolute;
                @extend %neutral-50-bg
            }
        }
    }
    .reaction-section {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .waf-showcase.widget-layout-01 {
        bottom: 0;
        left: 0;
        width: calc(100% - var(--standings-btn-width, 25.5rem));
    }
}
