@use "../../config/" as *;
.waf-game-hub {
    @extend %neutral-50-bg;
    @extend %py-3;
    @extend %relative;
    &::before {
        content: '';
        width: var(--window-inner-width);
        z-index: -1;
        @extend %h-100;
        @extend %neutral-50-bg;
        @extend %pos-center;
    }
    .title {
        @extend %font-18-pm;
    }
    .waf-head {
        @extend %mb-3;
    }
    .card {
        &-item {
            @extend %full-radius;
            @extend %hidden;
        }
        &-list {
            @include card-count(1.1, var(--space-3));
        }
    }
}
@include mq(col-md) {
    .waf-game-hub {
        padding-block: var(--space-12);
        .card {
            &-list {
                @include card-count(2.5, var(--space-3));
            }
        }
    }
}
@include mq(col-lg) {
    .waf-game-hub {
        .card {
            &-list {
                @include card-count(3, var(--space-3));
            }
        }
    }
}