@use "../config/" as *;
.waf-showcase {
  --_content-margin: var(--space-3);
  margin-inline: var(--space-3-neg);
  .layout-wrapper {
    max-width: unset;
  }
  .preview-swiper {
    .article {
      &-thumbnail {
        @extend %relative;
        &::after {
          content: "";
          background: linear-gradient(0deg, var(--neutral-1000) -24.56%, hsl(var(--hsl-neutral-1000) / .53) 37.68%, hsl(var(--hsl-neutral-1000) / 0) 93.24%);
          pointer-events: none;
          @include position-combo(inset);
        }
      }
      &-content {
        padding-inline: var(--_content-margin);
        margin-top: var(--space-12-neg);
        background: linear-gradient(0deg, var(--neutral-900) 55.77%, hsl(var(--hsl-neutral-900) / 0) 86.15%);
        backdrop-filter: blur(.2rem);
        z-index: var(--z-showcase-content);
        flex-wrap: wrap;
        @extend %pb-10;
        @extend %relative;
        @extend %neutral-50;
        @extend %flex-c-c;
        & > a {
          flex-basis: 100%;
        }
      }
      &-title {
        font-weight: 500;
        @include truncate(2, 18, 1.6);
        @extend %mb-4;
      }
    }
    .img-box {
      aspect-ratio: 4/3;
      img {
        object-fit: cover;
        object-position: top center;
      }
    }
  }
  .item-type-icon {
    @extend %d-none;
  }
  .item-type-video .item-type-icon {
    z-index: map-get($map: $zindex, $key: default);
    box-shadow: 0 0 0 .5rem hsl(var(--hsl-neutral-50) / .2), 0 0 0 1rem hsl(var(--hsl-neutral-50) / .05);
    @include position-combo("x-center", 29.9%);
    @extend %rounded-radius;
    @extend %flex;
    @extend %neutral-50-bg-3;
    @extend %font-0;
    &::before {
      font-size: 1.6rem;
      @extend %primary-500;
    }
  }
  .meta {
    &-date {
      line-height: 1;
      @extend %flex-n-c;
      @extend %gap-1;
      @extend %font-12;
      &::before {
        @include icon(calendar, 12);
      }
    }
    &-category {
      height: 2rem;
      padding-inline: .8rem;
      border-radius: .2rem;
      font: 500 1rem/1 $font-primary;
      @include position(null, null, calc(100% + var(--space-2)), var(--_content-margin));
      @extend %flex-n-c;
      @extend %neutral-1000-bg-5;
      @extend %primary-500;
    }
  }
  .swiper-button {
    &-prev,
    &-next {
      @extend %d-none;
    }
  }
  .swiper-pagination {
    height: .6rem;
    bottom: var(--space-4);
    @extend %flex-c-n;
    @extend %gap-1;
    @extend %w-100;
    &.swiper-pagination-bullets .swiper-pagination-bullet {
      margin-inline: 0;
    }
    &-bullet {
      width: 1.6rem;
      transition: width .3s ease-in, background-color .3s ease-in;
      @extend %h-100;
      @extend %rounded-radius;
      @extend %neutral-50-bg-4;
      &-active {
        width: 3.2rem;
        background-color: var(--primary-500);
      }
    }
  }
  .reaction-section {
    @extend %ml-auto;
  }
  .thumbnail-swiper {
    @extend %d-none;
    .article-meta {
      @extend %d-none;
    }
  }
}
@include mq(col-md) {
  .waf-showcase {
    --_content-margin: 8rem;
    .preview-swiper {
      .article {
        &-content {
          width: 100%;
          // @include position-combo(bl);
        }
        &-title {
          // font-size: 2rem;
          // line-height: 1.5;
        }
      }
      .img-box {
        aspect-ratio: 16/9;
      }
    }
  }
}
@include mq(col-lg) {
  .waf-showcase {
    --_article-content-width: max(25.4rem, 18.6%);
    --_content-margin: var(--space-4);
    --_swiper-button-width: 3.9rem;
    --_swiper-button-height: 3.5rem;
    --_thumbnail-swiper-height: 7rem;
    position: relative;
    .layout-wrapper {
      display: flex;
    }
    .preview-swiper {
      width: 100%;
      flex-shrink: 0;
      .article {
        &-wrap {
          display: flex;
        }
        &-thumbnail {
          width: calc(100% - var(--_article-content-width));
          &::after {
            width: 100%;
            height: 70.24%;
            inset: unset;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, var(--neutral-1000) -20.67%, hsl(var(--hsl-neutral-1000) / .53) 27.13%, hsl(var(--hsl-neutral-1000) / 0) 50.11%);
          }
        }
        &-content {
          width: var(--_article-content-width);
          height: 100%;
          padding-inline: var(--space-4);
          bottom: unset;
          left: unset;
          background: var(--secondary-1000);
          align-content: flex-start;
          padding-bottom: 0;
          margin-block: auto;
          position: relative;
          isolation: isolate;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 100vh;
            z-index: -1;
            pointer-events: none;
            @include background(var(--secondary-1000), "patterns/sidenav.png", bottom/cover no-repeat);
          }
        }
        &-meta {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          padding-inline: var(--space-4);
          @include flex-config(flex, column-reverse, space-between, null);
          .meta {
            width: max-content;
          }
        }
        &-title {
          // font-weight: 500;
          // -webkit-line-clamp: 4;
        }
      }
      .img-box::after {
        content: "";
        background: linear-gradient(180deg, var(--neutral-1000) -3.2%, hsl(var(--hsl-neutral-1000) / 0) 11.92%);
        pointer-events: none;
        @include position-combo(inset);
      }
    }
    .meta-category {
      background: hsl(var(--hsl-neutral-1000) / .8);
    }
    .item-type-video .item-type-icon,
    .swiper-pagination {
      display: none;
    }
    .swiper-button {
      &-prev,
      &-next {
        width: var(--_swiper-button-width);
        height: var(--_swiper-button-height);
        border: unset;
        border-radius: unset;
        top: unset;
        bottom: calc(var(--space-6) + var(--_thumbnail-swiper-height));
        transform: unset;
        @include flex-config(flex, null, center, center);
      }
      &-prev {
        border-radius: var(--half-radius) 0 0 var(--half-radius);
        left: calc(100% - var(--_article-content-width) + var(--_content-margin));
      }
      &-next {
        border-radius: 0 var(--half-radius) var(--half-radius) 0;
        right: unset;
        left: calc(100% - var(--_article-content-width) + var(--_content-margin) + var(--_swiper-button-width));
        // background: var(--primary-500);
        &::after {
          // color: var(--secondary-1000);
        }
      }
    }
    .social-share {
      display: inline-block;
      position: relative;
      right: unset;
      bottom: unset;
    }
    .thumbnail-swiper {
      width: calc(var(--_article-content-width) - var(--_content-margin));
      display: block;
      position: absolute;
      bottom: var(--space-3);
      left: calc(100% - var(--_article-content-width) + var(--_content-margin));
      .article {
        &-wrap {
          height: var(--_thumbnail-swiper-height);
          display: flex;
        }
        &-thumbnail {
          flex-shrink: 0;
          aspect-ratio: 4/3;
          .img-box {
            border-radius: 0;
          }
        }
        &-content {
          padding-inline: var(--space-2);
          background: hsl(var(--hsl-neutral-1000) / .5);
          @include flex-config(flex, null, null, center);
          .meta-date {
            display: none;
          }
          .meta-category {
            display: none;
          }
        }
        &-title {
          color: var(--neutral-50);
          font: 500 1rem/1.6 $font-primary;
          @include truncate-text(3);
        }
      }
    }
  }
}
@include mq(col-xl) {
  .waf-showcase {
    .preview-swiper {
      .article {
        &-thumbnail {
          height: calc(var(--window-inner-height) - var(--standings-strip-height));
        }
        &-content {
          // padding-top: 15%;
        }
      }
      .img-box {
        aspect-ratio: unset;
        border-radius: 0;
        height: 100%;
      }
    }
    .meta-category {
      // top: 35%;
    }
    .thumbnail-swiper {
      bottom: calc(var(--standings-strip-height) + var(--space-3));
    }
    .swiper-button {
      &-prev,
      &-next {
        bottom: calc(var(--standings-strip-height) + var(--space-6) + var(--_thumbnail-swiper-height));
      }
    }
  }
}