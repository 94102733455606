@use "../config/" as *;
.waf-standings {
    bottom: -100%;
    left: 0;
    transition: bottom 500ms ease-in-out;
    height: var(--window-inner-height);
    @extend %w-100;
    @extend %fixed;
    z-index: var(--z-standing);
    .layout-wrapper {
        @extend %p-2;
        @extend %neutral-50-bg;
    }
    .waf-head {
        @extend %flex-sb-c;
        @extend %mb-3;
        .title {
            @extend %secondary-1100;
        }
    }
    .waf-body {
        height: calc(100% - 9rem);
    }
    .waf-footer {
        bottom: 0;
        left: 0;
        @extend %w-100;
        @extend %fixed;
    }
    .accordion-btn {
        height: var(--standings-strip-height);
        padding-bottom: env(safe-area-inset-bottom);
        @extend %secondary-800-bg;
        @extend %w-100;
        .btn-text {
            @extend %neutral-50;
            @extend %font-12-pm;
            @extend %uppercase;
            @extend %relative;
            &:before {
                content: "\e852";
                font: 1rem/1.8rem "waf-font-icon";
                left: -2rem;
                top: -0.3rem;
                rotate: -90deg;
                transition: rotate 200ms ease-in-out;
                @extend %neutral-50;
                @extend %absolute;
            }
        }
    }
    .btn-accordion-trigger {
        right: 1rem;
        @extend %absolute;
        &::before {
            content: "\e807";
            font: 1rem/1.8rem "waf-font-icon";
            rotate: 0deg;
            transition: rotate 200ms ease-in-out;
            @extend %d-inline-block;
        }
        .btn-text {
            @extend %font-0;
        }
        &[aria-expanded="true"]::before {
            rotate: 180deg;
        }
    }
    .table-data {
        width: 18%;
    }
    .matches-position {
        width: 15%;
    }
    .matches-team {
        width: 25%;
    }
    .form-guide {
        width: 100%;
    }
    .table {
        &-head {
            .form-guide {
                display: none;
            }
        }
        &-body {
            @extend %overflow;
            .table-row {
                border-bottom: 0.1rem solid var(--secondary-200, 0.5);
            }
            .form-guide {
                &-listing {
                    gap: var(--space-3);
                    @extend %flex;
                    &:before {
                        content: "Last 3 Matches";
                        left: 3.5rem;
                        top: 8rem;
                        @extend %absolute;
                        @extend %font-12-pm;
                    }
                }
            }
        }
        .waf-accordion-head {
            @extend %relative;
            .form-guide {
                height: 0;
                transition: height 400ms ease-in-out;
                @extend %hidden;
                &-listing:before {
                    opacity: 0;
                }
            }
            .active {
                .form-guide {
                    height: 6rem;
                    transition: height 400ms ease-in-out;
                    &-listing:before {
                        opacity: 1;
                        transition: opacity 1s ease-in;
                    }
                }
            }
        }
        .highlight {
            @extend %primary-500-bg;
            @extend %full-radius;
        }
    }
    .standings-table{
        @extend %relative;
        &::before {
            content: "";
            pointer-events: none;
            @include background(null, "patterns/standing.png", bottom/contain no-repeat);
            @include position-combo(bl);
            @extend %w-100;
            @extend %h-100;
        }
    }
}
.waf-standings.active {
    bottom: 0;
    transition: bottom 500ms ease-in-out;
    .accordion-btn {
        .btn-text {
            &:before {
                rotate: 90deg;
                transform: unset;
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-standings {
        height: var(--window-inner-height);
        width: var(--standing-width);
        left: unset;
        right: 0;
        .table-body {
            height: calc(100% - var(--table-header-height));
        }
        .waf-footer {
            width: max(25.4rem, 18.6%);
            ;
            left: unset;
            right: 0;
            transition: width 400ms linear;
        }
        &.active {
            .waf-footer {
                width: 37.5rem;
            }
        }
    }
}