@use "../config/" as *;
.waf-squad {
    --_player-width: 27rem;
    @extend %py-6;
    @extend %relative;
    &::before {
        content: "";
        width: var(--window-inner-width);
        z-index: map-get($map: $zindex, $key: patterns);
        pointer-events: none;
        @include background(var(--neutral-50), "cssimages/pattern/mobile/squad-bg.png", center / cover no-repeat);
        @extend %h-100;
        @extend %pos-center;
    }
    .head-tab li:last-child{
        @extend %mr-2;
    }
    .squad {
        &-data-wrapper {
            flex-direction: column-reverse;
            @extend %flex;
        }
        &-thumb-swiper {
            @extend %d-none;
            @extend %mt-6;
            .swiper-button {
                @extend %d-none;
            }
        }
        &-thumb-slide {
            opacity: 0.5;
            filter: grayscale(1);
            @include border(1, neutral-1000, 2, top);
            @extend %flex-n-c;
            @extend %gap-3;
            @extend %py-3;
            &.swiper-slide-thumb-active,
            &.active {
                opacity: 1;
                filter: unset;
                .player-logo {
                    border: 0.2rem solid var(--primary-500);
                }
            }
        }
        &-thumb-wrapper {
            @extend %pl-5;
        }
        &-player-name {
            @extend %uppercase;
        }
    }
    .player {
        &-list {
            @extend %flex;
        }
        &-image {
            @extend %relative;
            @extend %m-auto;
            @extend %flex-c-c;
            img {
                width: var(--_player-width);
                @extend %m-auto;
            }
            &::after {
                content: "";
                background: transparent;
                width: calc(var(--_player-width) + 4rem);
                height: calc(var(--_player-width) + 4rem);
                position: absolute;
                top: calc(18% - 2rem);
                margin: auto;
                border: 0.2rem dashed var(--secondary-1000);
                z-index: var(--z-negative);
                opacity: 0.4;
                -webkit-mask-image: linear-gradient(to bottom,
                        var(--neutral-50) 0%,
                        var(--neutral-50) 15%,
                        transparent 60%,
                        transparent 100%);
                mask-image: linear-gradient(to bottom,
                        var(--neutral-50) 0%,
                        var(--neutral-50) 15%,
                        transparent 52%,
                        transparent 100%);
                @extend %circle-radius;
            }
            &::before {
                content: "";
                position: absolute;
                top: 18%;
                margin: auto;
                z-index: var(--z-negative);
                width: var(--_player-width);
                height: var(--_player-width);
                -webkit-mask-image: linear-gradient(to bottom,
                        var(--neutral-50) 0%,
                        var(--neutral-50) 15%,
                        transparent 60%,
                        transparent 100%);
                mask-image: linear-gradient(to bottom,
                        var(--neutral-50) 0%,
                        var(--neutral-50) 15%,
                        transparent 60%,
                        transparent 100%);
                @extend %neutral-100-bg-2;
                @extend %circle-radius;
                @extend %pos-x-center;
            }
        }
        &-position {
            width: max-content;
            @extend %px-1;
            @extend %secondary-300-bg;
            @extend %mb-3;
            @extend %half-radius;
            .txt {
                @extend %font-10-pm;
                @extend %secondary-1000;
                @extend %uppercase;
            }
        }
        &-info-list {
            @include border(1, neutral-1000, 1, y);
            @include grid(4, var(--space-4));
            @extend %py-2;
            @extend %w-100;
        }
        &-bio {
            margin-block: var(--space-3) var(--space-5);
        }
        &-bio-text,
        .text {
            opacity: 0.7;
            @include truncate(5, 12);
            height: 11.5rem;
            @extend %neutral-800;
            @extend %font-12-pr;
            @extend %mb-3;
        }
        &-bio-title {
            @extend %d-none;
        }
        &-logo {
            object-fit: contain;
            object-position: center;
            flex-shrink: 0;
            padding: 0.1rem;
            @extend %w-100;
            aspect-ratio: 1/1;
            @include border(1, neutral-1000, 2);
            @extend %circle-radius;
            @extend %neutral-100-bg-2;
        }
        &-name {
            @extend %flex-fs-c;
            &::after {
                width: 1.7rem;
                aspect-ratio: 1/1;
                @extend %font-13-pb;
            }
        }
    }
    .left-section {
        @include border(0.19, secondary-1100, 10, top);
        @extend %pt-4;
    }
    .fname {
        @extend %font-24-pr;
    }
    .lname {
        @extend %font-24-pb;
    }
    .name {
        @extend %secondary-1000;
    }
    .item-info {
        display: grid;
        @extend %gap-2;
    }
    .list-item {
        flex-direction: column-reverse;
        @extend %relative;
        @extend %flex-c-c;
        @extend %gap-2;
        &:not(:last-child) {
            &::after {
                content: "";
                width: 0.1rem;
                @include position(null, var(--space-2-neg));
                @extend %h-100;
                @extend %neutral-1000-bg-2;
            }
        }
        .label {
            @extend %font-10-pr;
            @extend %neutral-600;
            @extend %uppercase;
        }
        .number {
            @extend %font-20-pb;
            @extend %secondary-1000;
        }
    }
    .view-more {
        width: max-content;
        padding: var(--space-2) var(--space-5);
        @extend %flex-n-c;
        @extend %gap-1;
        @extend %primary-500-bg;
        @extend %font-12-pm;
        @extend %secondary-1000;
        @extend %uppercase;
        @extend %half-radius;
    }
    .thumb-player-image {
        width: 6.7rem;
        aspect-ratio: 1/1;
    }
    .player-name {
        gap: var(--space-3);
        @extend %uppercase;
    }
    .captain {
        .player-name {
            &:after {
                content: "c";
                @extend %uppercase;
            }
        }
    }
    .captain,
    .overseas {
        .player-name {
            @extend %gap-3;
            &::after {
                @extend %flex-c-c;
                @extend %secondary-1000;
                @extend %circle-radius;
                @extend %primary-500-bg;
            }
        }
    }
    .overseas {
        .player-name {
            @extend %gap-3;
            &::after {
                content: "\e887";
                font: 1.3rem $font-icon;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-squad {
        --_player-width: 35rem;
        padding-block: var(--space-12) 0;
        &::before {
            @include bgImg("cssimages/pattern/web/squad-bg.png");
        }
        .waf-head {
            a {
                font-size: 1.2rem;
            }
        }
        .title {
            font-size: 2.2rem;
        }
        .name {
            font-size: 3rem;
        }
        .waf-body {
            display: grid;
            grid-template-columns: 80% 20%;
        }
        .squad {
            &-data-wrapper {
                flex-direction: unset;
            }
            &-thumb-swiper {
                display: flex;
                width: 100%;
                height: 49rem;
                position: relative;
                .swiper-slide-thumb-active {
                    position: relative;
                    &:before {
                        content: "";
                        height: 1.1rem;
                        width: 1.1rem;
                        border-radius: 50%;
                        position: absolute;
                        left: -2.5rem;
                        background-color: clr(primary-500);
                    }
                    &::after {
                        content: "";
                        height: .1rem;
                        width: 2.3rem;
                        left: -2.1rem;
                        position: absolute;
                        background-color: clr(primary-500);
                    }
                }
            }
            &-player-name {
                font: 1.3rem $font-primary;
                .name {
                    font-size: 1.3rem;
                    font-weight: 400;
                    display: block;
                }
            }
        }
        .swiper-slide-thumb-active {
            .squad-player-name {
                .fname {
                    font-weight: 500;
                }
                .lname {
                    font-weight: 700;
                }
            }
        }
        .section-item {
            display: flex;
        }
        .left-section {
            width: 40%;
            flex-direction: column;
            border-top: unset;
            align-items: flex-start;
            justify-content: center;
            .card-action {
                margin-bottom: var(--space-5);
            }
        }
        .middle-section {
            width: 60%;
            align-items: flex-end;
            justify-content: flex-end;
            &::after {
                content: "";
                width: 0.1rem;
                height: calc(100% + 8rem);
                background: clr(neutral-1000, 1);
            }
        }
        .player {
            &-swiper {
                width: 100%;
            }
            &-bio-text {
                font-size: 1.4rem;
            }
            &-position {
                .txt {
                    font-size: 1.2rem;
                }
            }
            &-image {
                &::after {
                    width: calc(var(--_player-width) + 10rem);
                    height: calc(var(--_player-width) + 10rem);
                    top: calc(18% - 3rem);
                }
                &::before {
                    width: calc(var(--_player-width) + 2rem);
                    height: calc(var(--_player-width) + 2rem);
                }
            }
            &-name {
                &::after {
                    width: 2.4rem;
                    aspect-ratio: 1/1;
                }
            }
        }
        .view-more {
            font-size: 1.4rem;
        }
        .list-item {
            .number {
                font-size: 2.2rem;
            }
        }
        //need to discuss
    }
}
@include mq(col-xl) {
    .waf-squad {
        .swiper-button {
            &-prev {
                left: 6rem;
            }
            &-next {
                right: 6rem;
            }
        }
    }
}