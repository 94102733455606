@use "../config/" as *;

.waf-standings {
    .standings-table {
        border-radius: 1.5rem;
        border: none;
        height:100%;
        overflow: hidden;
        @extend %mb-5;
        @extend %neutral-200-bg-1;
    }
    .layout-wrapper {
        @extend %h-100;
    }
    .waf-head {
        @extend %flex-sb-c;
        @extend %mb-3;
    }
   
    .waf-accordion-head {
        @extend %w-100;
        .table-row {
            flex-wrap: wrap;
        }
    }
    .matches-team {
        @extend %flex-fs-c;
    }
    .form-guide {
        order: 9;
    }
    .win {
        @extend %success-500-bg;
        .text{
            @extend %neutral-50;
        }
    }
    .lost {
        @extend %error-500-bg;
        .text{
            @extend %neutral-50;
        }
    }
    .draw {
        border: 0.1rem solid var(--neutral-200);
        @extend %neutral-50-bg;
        .text {
            @extend %neutral-1000;
        }
    }
    .team-image {
        --img-height:3.5rem;
        --img-width:3.5rem;
        width: var(--img-width);
        height:var(--img-height);
        flex-shrink: 0;
        @extend %p-1;
        @extend %mr-3;
        @extend %neutral-50-bg;
        @extend %circle-radius;
    }

    .full-name {
        @extend %d-none;
    }
    .table {
        border: none;
        height: 100%;
        &-head {
            border-radius: var(--space-3) var(--space-3) 0 0;
            @extend %secondary-1000-bg;
            .text {
                @extend %primary-500;
                @extend %font-12-pm;
            }
            .table-data {
                height: var(--table-header-height);
                @extend %primary-500;
            }
            .matches-team{
                @extend %flex-c-c;
            }
        }
        &-body {
            height: calc(100% - var(--table-header-height));
            @extend %relative;
            .form-guide {
                @extend %px-3;
                @extend %flex-fe-c;
                &-listing {
                    gap: var(--space-3);
                    @extend %flex;
                }
                &-item {
                    height: 3.2rem;
                    width: 3.2rem;
                    @extend %flex-c-c;
                    @extend %circle-radius;
                    .text {
                        @extend %font-12-pm;
                    }
                }
            }
            .table-data {
                height:var(--table-row-height);
                &-wrap {
                    @extend %flex-c-c;
                }
            }
        }

        &-data {
            @extend %font-14-pm;
            @extend %secondary-1000;
        }

        &-row {
            @extend %flex-fs-c;
            &:last-child {
                border-bottom: unset;
            }
        }
    }
    .highlight {
        @extend %primary-500-bg;
    }
}





