@use "../config/" as *;
%card-tag-styles {
  .item-wrap::before {
    height: 1.8rem;
    line-height: 1;
    @include position(null, null, calc(var(--_stats-height) + 1.4rem), var(--_content-left-margin));
    @extend %rounded-radius;
    @extend %px-2;
    @extend %flex-n-c;
    // @extend %neutral-50-bg-1;
    @extend %neutral-50;
    @extend %font-11-pm;
  }
  @include mq(col-lg) {
    .item-wrap::before {
      height: 2.7rem;
      padding-inline: 1.7rem;
      font-size: 1.4rem;
    }
  }
  @include mq(col-xl) {
    .item-wrap::before {
      bottom: calc(var(--_stats-height) + var(--space-4));
    }
  }
}
.waf-row-statslisting {
  padding-block: var(--space-1) var(--space-8);
  overflow-x: clip;
  isolation: isolate;
  @extend %relative;
  @extend %secondary-1000-bg;
  &::before {
    content: "";
    width: 106.7%;
    aspect-ratio: .72;
    z-index: map-get($map: $zindex, $key: patterns);
    pointer-events: none;
    @include background(null, "cssimages/pattern/statslisting-bg-m.png", center / cover no-repeat);
    @include position-combo("x-center", 10rem);
  }
  .col-lg-6:not(:last-child) {
    margin-bottom: var(--space-4);
  }
}
.waf-statslisting {
  --_player-image-width: 10rem;
  --_content-left-margin: calc(var(--_player-image-width) + var(--space-5));
  --_stats-height: 5.8rem;
  --_name-height: 9.4rem;
  --_statslisting-border-radius: 1rem;
  padding-inline: var(--space-3);
  margin-inline: var(--space-2-neg);
  .card {
    &-item {
      isolation: isolate;
      @extend %relative;
      &::before {
        content: "";
        width: 100%;
        height: var(--_name-height);
        border-top-right-radius: var(--_statslisting-border-radius);
        @include position(null, null, var(--_stats-height), 0);
        @extend %secondary-800-bg;
      }
    }
    &-head {
      width: 82.9%;
      min-width: 27rem;
      height: 3.1rem;
      padding-left: var(--_content-left-margin);
      border-radius: var(--_statslisting-border-radius) var(--_statslisting-border-radius) 0 0;
      @include position(3.4rem, null, null, 0);
      @extend %flex-n-c;
      @extend %secondary-900-bg;
    }
  }
  .title {
    @extend %neutral-50;
    @extend %font-10-pm;
  }
  .batting,
  .bowling,
  .wicketkeeper,
  .allrounder {
    @extend %card-tag-styles;
  }
  .batting .item-wrap::before,
  .bowling .item-wrap::before,
  .wicketkeeper .item-wrap::before,
  .allrounder .item-wrap::before {
    @extend %secondary-700-bg;
  }
  .batting .item-wrap::before {
    content: "BATTER";
  }
  .bowling .item-wrap::before {
    content: "BOWLER";
  }
  .wicketkeeper .item-wrap::before {
    content: "WICKETKEEPER";
  }
  .allrounder .item-wrap::before {
    content: "ALLROUNDER";
  }
  .item-wrap {
    @extend %flex-c-fe;
    @extend %relative;
    @extend %flex-wrap;
    &::after {
      content: "";
      height: var(--_stats-height);
      border-radius: 0 0 var(--_statslisting-border-radius) var(--_statslisting-border-radius);
      z-index: map-get($map: $zindex, $key: patterns);
      pointer-events: none;
      @extend %w-100;
      @extend %pos-bl;
      @extend %secondary-900-bg;
    }
  }
  .logo {
    min-height: 15rem;
    flex-basis: var(--_player-image-width);
    @extend %flex-n-fe;
    @extend %ml-2;
    @extend %relative;
    @extend %isolate;
    &:after {
      content: "";
      height: 15%;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, clr(secondary-800) 26.86%, transparent);
      z-index: var(--zindex1);
      pointer-events: none;
      @extend %absolute;
      @extend %w-100;
    }
  }
  .rank {
    display: none;
  }
  .name {
    max-width: calc(100% - var(--_player-image-width) - var(--space-2));
    height: var(--_name-height);
    padding-inline: var(--space-3);
    border-top-right-radius: var(--_statslisting-border-radius);
    flex-basis: calc(100% - var(--_player-image-width) - var(--space-2));
    contain: paint;
    line-height: 1;
    @extend %uppercase;
    @extend %py-3;
    @extend %relative;
    @extend %neutral-50;
    @extend %font-20;
    &::after {
      content: "";
      border-top-right-radius: var(--_statslisting-border-radius);
      transform: translate(3%, 0);
      pointer-events: none;
      @include position-combo(inset);
      @include background(null, "cssimages/pattern/statslisting-card-bg.svg", center right / contain no-repeat);
    }
  }
  .fname,
  .lname {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .lname {
    font-weight: 700;
    @extend %d-block;
  }
  .total-points {
    flex-basis: 21.2%;
    height: var(--_stats-height);
    @extend %flex-column-c-n;
    @extend %relative;
    @extend %gap-1;
    @extend %text-center;
    &:not(.total-points ~ .total-points) {
      @extend %ml-3;
    }
    &::after {
      content: "";
      width: .1rem;
      height: 3.5rem;
      @include position-combo("y-center", 0);
      @extend %neutral-50-bg-1;
    }
    + .total-points {
      flex-basis: 27%;
    }
    &:nth-last-child(1 of .total-points) {
      flex-basis: 33%;
    }
  }
  .points {
    height: 2.5rem;
    @extend %font-20-pm;
    @extend %primary-500;
  }
  .label {
    line-height: 1.2;
    @extend %d-block;
    @extend %secondary-100-7;
    @extend %font-10-pm;
    @extend %uppercase;
  }
  .more-btn {
    width: 9%;
    height: var(--_stats-height);
    @extend %mr-3;
    @extend %flex-fe-c;
    @extend %font-0;
    &::before {
      @include icon(chevron-right, 16);
      @extend %neutral-50;
    }
  }
}
@media screen and (max-width: $small-mobile-max-width) {
  .waf-statslisting {
    --_content-left-margin: calc(var(--_player-image-width) + var(--space-3));
    .name {
      max-width: calc(100% - var(--_player-image-width));
      flex-basis: calc(100% - var(--_player-image-width));
      font-size: 1.6rem;
    }
    .logo {
      margin-left: 0;
    }
    .total-points:not(.total-points~.total-points) {
      margin-left: 0;
    }
    .more-btn {
      margin-right: 0;
    }
  }
}
@include mq(col-md) {
  .waf-row-statslisting {
    padding-block: 0 var(--space-12);
    &::before {
      content: unset;
    }
    .row {
      gap: var(--space-3);
    }
    .col-lg-6 {
      flex-basis: calc(50% - .75rem);
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    .points {
      height: 3.2rem;
    }
  }
  .waf-statslisting {
    padding-inline: 0;
  }
}
@include mq(col-lg) {
  .waf-row-statslisting {
    isolation: unset;
    position: static;
  }
  .waf-statslisting {
    --_player-image-width: 32%;
    --_content-left-margin: calc(var(--_player-image-width) + var(--space-8));
    --_stats-height: 9.7rem;
    --_statslisting-border-radius: 1.7rem;
    .card {
      &-item::before {
        height: 40%;
      }
      &-head {
        height: 5.2rem;
        top: unset;
        bottom: calc(var(--_stats-height) + 40%);
      }
    }
    .title {
      font-size: 1.6rem;
    }
    .name {
      max-width: unset;
      flex-basis: calc(100% - var(--_player-image-width) - var(--space-3));
      height: unset;
      aspect-ratio: 2.5;
      padding-block: var(--space-2);
      padding-left: var(--space-5);
      position: unset;
      font-size: 2.4rem;
      line-height: 1.4;
      contain: unset;
      &::after {
        width: 100%;
        height: 40%;
        inset: unset;
        right: 0;
        bottom: var(--_stats-height);
        transform: unset;
      }
    }
    .logo {
      margin-left: var(--space-3);
      min-height: 27rem;
    }
    .total-points::after {
      height: 5.7rem;
      bottom: 2rem;
    }
    .points {
      font-size: 3rem;
    }
    .label {
      font-size: 1.4rem;
    }
    .more-btn::before {
      font-size: 2rem;
    }
  }
}
@include mq(col-xl) {
  .waf-statslisting {
    .name {
      padding-block: var(--space-4);
    }
    .total-points:not(.total-points~.total-points) {
      margin-left: var(--space-5);
    }
    .more-btn {
      margin-right: var(--space-5);
    }
  }
}