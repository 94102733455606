@use "../../config/" as *;
.waf-app-banner {
    overflow: hidden;
    @include background(null, "banner/mobile/app-ad-bg.png", top center / cover no-repeat var(--secondary-900));
    @extend .full-width;
    .image-wrap {
        img {
            aspect-ratio: 3 / 2;
            object-fit: cover;
            @extend %h-100;
            @extend %w-100;
        }
    }
    .layout-wrapper {
        max-width: unset;
    }
    .title {
        @extend %primary-500;
        @extend %font-30-pb;
        @extend %uppercase;
        @extend %py-3;
    }
    .content {
        @extend %neutral-50;
        @extend %font-13-pr;
    }
    .content-wrap {
        @extend %text-center;
    }
    .btn-wrap {
        gap: var(--space-3);
        @extend %mt-6;
        @extend %flex-c-n;
    }
    .store {
        width: 20rem;
    }
    .banner-body {
        @extend %px-3;
        @extend %pb-6;
    }
    .banner-head {
        @extend %pt-4;
    }
    // START DOWNLOAD APP BANNER PAGE CSS START
    &.app-download {
        background: none;
        @extend %mx-auto;
        @extend %p-0;
        @extend %hidden;
        @extend %mb-6;
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .title {
            @extend %font-20-pb;
            @extend %neutral-50;
        }
        .content {
            line-height: 1.7rem;
            @extend %font-11-pr;
        }
        .waf-body {
            max-height: 50rem;
            @include background(null, "banner/mobile/app-ad-bg.png", top center / cover no-repeat var(--secondary-900));
            @extend %full-radius;
            @extend %pt-2;
        }
        .app-banner-wrap {
            flex-direction: column;
            @extend %flex-n-n;
        }
        .image-wrap {
            border: 0;
            @extend %mt-6-neg;
        }
        .banner-body {
            .title {
                font-weight: 400;
                @extend %font-20;
            }
            .sub-title {
                font-weight: 500;
                @extend %primary-500;
                @extend %d-block;
            }
        }
        .btn-wrap {
            @extend %mt-2;
        }
        .store {
            width: 11.5rem;
        }
    }
    // START DOWNLOAD APP BANNER PAGE CSS END
}
@media screen and (min-width: $desktop-min-width) {
    .waf-app-banner {
        padding: 0;
        @include background(null, "banner/app-ad-bg.png", top center / cover no-repeat var(--secondary-900));
        .image-wrap {
            border: 0;
            width: 50rem;
            img {
                aspect-ratio: unset;
                width: 100%;
            }
        }
        .title {
            padding-top: 0;
            font-size: 3.5rem
        }
        .app-banner-wrap {
            @include flex-config(flex, row, space-between, flex-end);
        }
        .banner-head {
            width: 30%;
            padding-top: 0;
        }
        .banner-body {
            width: 60%;
            padding-top: var(--space-6);
        }
        .content {
            font-size: 1.6rem;
        }
        .content-wrap {
            text-align: left;
        }
        .btn-wrap {
            justify-content: flex-start;
        }
        // START DOWNLOAD APP BANNER PAGE CSS START
        &.app-download {
            .waf-head {
                .title {
                    font-size: 2.8rem;
                    font-weight: 500;
                    padding-block: var(--space-3) var(--space-5);
                }
            }
            .waf-body {
                max-height: 30rem;
                @include background(null, "banner/app-ad-bg.png", top center / cover no-repeat var(--secondary-900));
            }
            .banner-head {
                width: 45%;
            }
            .banner-body {
                width: 55%;
                padding-top: var(--space-2);
                .title {
                    font-size: 4rem;
                }
                .sub-title {
                    font-weight: 700;
                }
            }
            .image-wrap {
                width: unset;
                img {
                    margin-inline: auto 0;
                }
            }
            .app-banner-wrap {
                flex-direction: row;
            }
            .content {
                font-size: 1.4rem;
            }
            .btn-wrap {
                margin-top: var(--space-4);
            }
            .store {
                width: 16rem;
            }
        }
        // START DOWNLOAD APP BANNER PAGE CSS END
    }
}