@use "./config" as *;
@forward "./main-scss/";
@forward "./showcase/showcase";
@forward "./showcase/marquee-showcase";
@forward "./listing/home-video-listing";
@forward "./listing/home-photo-listing";
@forward "./listing/listing-common";
@forward "./partials/home-tracker";
@forward "./fixture/home-fixture";
@forward "./statslisting/statslisting";
@forward "./partials/module/game-hub";
@forward "./partials/module/fan-poll";
@forward "./partials/module/app-ad-banner";
@forward "./standing/home-standing";
@forward "./squad/home-squad";
@forward "./swiper/swiper-bundle";
@forward "./listing/home-news-listing";
@forward "./standing/standing-common";
.waf-breadcrumb {
    display: none;
}
body:has(.waf-row-poll) {
    .waf-game-hub {
        padding-top: var(--space-3)
    }
}
.home {
    .footer-wrap {
        padding-bottom: calc(var(--standings-strip-height) * 2);
    }
}
.waf-component.waf-shop-embed {
    margin-inline: -15px;
    background-color: #fff;
    padding-block: 30px;
    .kCGcFX {
        font-size: 22px !important;
        color: #3B215D !important;
    }
}
.waf-tracker,.waf-squad {
    .head-tab {
        display: flex;
        justify-content: space-between;
        li:last-child{
            order: -1;
            a {
                display: flex;
                align-items: center;
                background-color: unset;
                padding: unset;
                width: max-content;
                &::after {
                    content: '';
                    display: block;
                    height: 3rem;
                    width: 9rem;
                    @extend %ml-2;
                    @include background(null, "clients/2024/dream11.png", center / contain no-repeat);
                }
            }
        }
      }
}

@media screen and (min-width: $tablet-min-width) {
    .home {
        .footer-wrap {
            padding-bottom: var(--standings-strip-height);
        }
    }
    .waf-component.waf-shop-embed {
        .layout-wrapper {
            max-width: unset;
        }
    }
    .waf-tracker,.waf-squad {
        .head-tab {
            padding-left: var(--space-4);
            width: 100%;
        }
    }
}