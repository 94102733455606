@use "../../config/" as *;
.waf-row-poll {
  @extend %pt-5;
  @extend %pb-2;
  @extend %neutral-50-bg;
  .sticky-container-poll,
  .sticky-container {
    align-items: flex-end;
  }
}
.waf-poll-banner {
  @extend %full-radius;
  @extend %hidden;
}
.waf-poll-wrapper {
  .title {
    @extend %secondary-1100;
  }
  .waf-head {
    @extend %flex-sb-n;
    @extend %mb-3;
  }
  .poll-section {
    @include background(
      var(--secondary-1000),
      null,
      top center / cover no-repeat
    );
    @extend %p-4;
    @extend %full-radius;
    @extend %mb-3;
  }
  .poll-head {
    .title {
      @extend %font-16-pr;
      @extend %neutral-50;
    }
  }
  .radiomark {
    height: 1.5rem;
    width: 1.5rem;
    @extend %relative;
  }
  .form-radio-input {
    width: 1.6rem;
    height: 1.6rem;
    opacity: 0;
    cursor: pointer;
    @extend %absolute;
  }
  .form-radio-input:checked {
    & ~ .radiomark {
      border: 0.1rem solid var(--primary-500);
      @extend %transparent-bg;
      &:after {
        content: "";
        transition: all 100ms linear 0s;
        width: 0.7rem;
        height: 0.7rem;
        @include position(0.3rem, null, null, 0.3rem);
        @extend %primary-500-bg;
        @extend %d-block;
        @extend %circle-radius;
      }
    }
  }
  .radio-container {
    @extend %flex-n-c;
  }
  .radio-box {
    cursor: pointer;
    user-select: none;
    @extend %hidden;
    @extend %half-radius;
    @extend %w-100;
    @extend %d-block;
    @extend %relative;
    @extend %neutral-50-bg-1;
    .text {
      z-index: var(--z-index1);
      @extend %neutral-50;
      @extend %pl-2;
    }
    .radiomark {
      z-index: var(--z-index1);
      @extend %neutral-50-bg-3;
      @extend %circle-radius;
    }
    .radio-container {
      @extend %p-2;
      cursor: pointer;
    }
    &-wrap {
      @include grid(1, var(--space-3));
    }
  }
  .poll-body {
    @extend %mt-4;
  }
  .poll-result-wrapper {
    @extend %ml-auto;
  }
  .poll-result {
    .progress-bar-holder {
      @include position-combo(tl);
      @extend %h-100;
      @extend %w-100;
    }
    .progress-bar {
      border-radius: 0 var(--half-radius) var(--half-radius) 0;
      @extend %d-inline-block;
      @extend %h-100;
      @extend %neutral-50-bg-1;
    }
    .form-radio-input:checked {
      & ~ .radiomark {
        border: 0.1rem solid var(--neutral-50);
        @extend %transparent-bg;
      }
      & ~ .radiomark:after {
        @extend %neutral-50-bg;
      }
      & ~ .poll-result-wrapper .progress-bar {
        @extend %primary-500-bg;
      }
    }
  }
  .progress-count {
    @extend %neutral-50;
  }
  .vote-now {
    .btn-fill {
      @extend %w-100;
      @extend %font-14-pm;
      @extend %secondary-1000;
      @extend %primary-500-bg;
      @extend %p-2;
      @extend %half-radius;
      @extend %mt-4;
    }
    .btn-disabled {
      cursor: not-allowed;
      background: clr(neutral-300, 9);
    }
  }
  .global-mgs {
    @extend %text-center;
    @extend %my-2;
    .message {
      @extend %font-16-pb;
      @extend %text-center;
      @extend %neutral-50;
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-row-poll {
    padding-block: var(--space-12);
  }
  .waf-poll-wrapper {
    .radio-box {
      &-wrap {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .vote-now {
      .btn-fill {
        width: 50%;
      }
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-poll-wrapper {
    margin-left: var(--space-2-neg);
    .poll-section {
      margin-bottom: 0;
    }
  }
}
